import {
  ArrayField,
  Button,
  Datagrid,
  Empty,
  FunctionField,
  List,
  Pagination,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const LabeledField = ({ label, source }: { label: string; source: string }) => (
  <Box sx={{ mb: 2 }}>
    <Typography variant="subtitle2" fontWeight={600} color="text.secondary">
      {label}
    </Typography>
    <TextField source={source} />
  </Box>
);

export const AccountShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <Box display="flex" flexWrap="wrap" justifyContent="space-between">
          <Typography variant="h5" gutterBottom>
            Account Details
          </Typography>
          {/*<Button color="error" onClick={handleDeleteClick} label="Delete" />*/}
        </Box>
        {/*<Box ml={2} display="flex" flexWrap="wrap">*/}
        {/*  <Box flex="1" minWidth="200px" mr={2}>*/}
        {/*    <LabeledField label="Name" source="name" />*/}
        {/*    <LabeledField label="Public ID" source="publicID" />*/}
        {/*    <LabeledField label="Demo organization" source="demo" />*/}
        {/*  </Box>*/}
        {/*  <Box flex="1" minWidth="200px">*/}
        {/*    <LabeledField label="Organization ID" source="organizationID" />*/}
        {/*    <LabeledField label="Status" source="status" />*/}
        {/*  </Box>*/}
        {/*  <Box flex="1" minWidth="200px">*/}
        {/*    <LabeledField label="Created At" source="createdAt" />*/}
        {/*    <LabeledField label="Updated At" source="updatedAt" />*/}
        {/*  </Box>*/}
        {/*</Box>*/}
        <Divider sx={{ my: 1 }} />
        {/*<Box mb={3}>*/}
        {/*  <Typography variant="h6" gutterBottom>*/}
        {/*    Members*/}
        {/*  </Typography>*/}
        {/*  <ArrayField source="members.items">*/}
        {/*    <Datagrid bulkActionButtons={false}>*/}
        {/*      <TextField source="account.firstName" label="First Name" />*/}
        {/*      <TextField source="account.lastName" label="Last Name" />*/}
        {/*      <TextField source="account.contactEmail" label="Email" />*/}
        {/*      <TextField source="role" label="Role" />*/}
        {/*      <TextField source="accountID" label="Account ID" />*/}
        {/*    </Datagrid>*/}
        {/*  </ArrayField>*/}
        {/*</Box>*/}
      </SimpleShowLayout>
    </Show>
  );
};
