import {
  BooleanInput,
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useGetRecordId,
  useNotify,
  useRedirect,
  useUpdate,
} from "react-admin";

export const OrganizationEdit = (props: any) => {
  const recordId = useGetRecordId();
  const [update] = useUpdate();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSubmit = async (data: any) => {
    const randomSessionID = `admin-${
      import.meta.env.VITE_APP_ENV
    }-session-${Date.now().toString()}`;

    try {
      await update(
        "organizations",
        {
          data: {
            input: {
              organizationID: recordId,
              name: data.name,
              demo: data.demo,
            },
            tracing: {
              sessionID: randomSessionID,
              pageID: `${import.meta.env.VITE_APP_ENV}.organizations.create`,
            },
          },
        },
        {
          onSuccess: () => {
            notify("Organization updated successfully");
            redirect("list", "organizations");
          },
          onError: (error) => {
            notify("Error updating organization", { type: "error" });
            console.error("Error updating organization:", error);
          },
        }
      );
    } catch (error) {
      console.error("Error in update function:", error);
    }
  };

  return (
    <Edit {...props}>
      <SimpleForm onSubmit={onSubmit} toolbar={<UserEditToolbar />}>
        <TextInput name={"name"} source="name" />
        <BooleanInput name={"demo"} source="demo" label="Demo" />
      </SimpleForm>
    </Edit>
  );
};

const UserEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);
